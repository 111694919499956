import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';
import { usePdf } from 'src/hooks/usePdf';

import { hasValue } from 'src/utils/hasValue';

import { ComponentProps } from 'lib/component-props';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import BaseEditFrame from 'components/BaseHelpers/EditFrame';
import Image from 'components/BaseHelpers/Image';
import Link from 'components/BaseHelpers/Link';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import StartIndex from 'components/BaseHelpers/StartIndex';
import StopIndex from 'components/BaseHelpers/StopIndex';
import Text from 'components/BaseHelpers/Text';

import { usePeopleInfo } from '../../../hooks/usePeopleInfo';

import styles from './PeopleProfessionalHeader.module.scss';

export type PeopleProfessionalHeaderProps = ComponentProps;

const PeopleProfessionalHeader = ({ rendering }: PeopleProfessionalHeaderProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const { isPdf } = usePdf();
  const route = sitecoreContext.route;
  const fields = route?.fields || {};
  const { image } = fields;
  const itemId = route?.itemId;

  const { level, title, department, name, practices, officesPhones, email } = usePeopleInfo(fields);
  const { hasValueOrIsEE, hasItemsOrIsEE } = useExpEditor();

  const EditFrame = ({ children }: { children?: React.ReactNode }): JSX.Element => (
    <BaseEditFrame
      itemId={itemId}
      fields={[
        'Title',
        'Level',
        'Department',
        'Prefix',
        'First Name',
        'Middle Initial',
        'Nick Name',
        'Last Name',
        'Suffix',
        'Key Practices',
        'Key Practices TC',
        'Services',
        'Services TC',
        'Offices',
        'Phone 1',
        'Phone 2',
        'Phone 3',
        'Business Email',
      ]}
    >
      {children}
    </BaseEditFrame>
  );

  return (
    <EditFrame>
      <AnimateInContainer className={cn(styles.main, 'spacer', { [styles['pdf-layout']]: isPdf })}>
        {isPdf && (
          <div className={styles['pdf-image-container']}>
            <Image className={styles['pdf-image']} field={image} fill={true} alt="" />
          </div>
        )}
        <div className={styles.content}>
          {hasValue(title) ? (
            <Text tag="p" className={styles.role} field={title} editable={false} />
          ) : (
            <>
              <Text tag="p" className={styles.role} field={level} editable={false} />
              <Text
                tag="p"
                className={styles['role-secondary']}
                field={department}
                editable={false}
              />
            </>
          )}
          <StopIndex />
          <Text tag="h1" className={styles.name} field={name} editable={false} />
          <StartIndex />
          <Text tag="p" className={styles.practices} field={practices} editable={false} />
          {(officesPhones.length || hasValueOrIsEE(email)) && (
            <ul className={cn(styles.links, styles.spacer)}>
              {officesPhones.map((item, index) => {
                return (
                  <li className={styles['link-item']} key={index}>
                    {item.office?.text && (
                      <LinkOrComponent className={styles.link} field={item.office} tag="span">
                        {item.office.text}
                      </LinkOrComponent>
                    )}
                    {item.phone && <Link className={styles.link} field={item.phone} />}
                  </li>
                );
              })}

              {email && (
                <>
                  <StopIndex />
                  <li className={styles['link-item']}>
                    <Link className={cn(styles.link, styles['email-link'])} field={email}>
                      <span
                        className={styles.email}
                        dangerouslySetInnerHTML={{ __html: email.emailHtml }}
                      />
                    </Link>
                  </li>
                  <StartIndex />
                </>
              )}
            </ul>
          )}
          {hasItemsOrIsEE(rendering?.placeholders?.['socialmediaicons']) && (
            <div className={cn(styles.spacer, styles['share-spacer'])}>
              <StopIndex />
              <ul className={cn(styles.share)}>
                <Placeholder
                  rendering={rendering}
                  name="socialmediaicons"
                  renderEach={(component, index) => (
                    <li className={styles.item} key={index}>
                      {component}
                    </li>
                  )}
                />
              </ul>
              <StartIndex />
            </div>
          )}
        </div>
      </AnimateInContainer>
    </EditFrame>
  );
};

export default PeopleProfessionalHeader;
