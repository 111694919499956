import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';
import { ChangeEvent, FormEvent, useEffect, useRef } from 'react';

import { getName } from 'src/utils/getName';

import { Website } from 'lib/component-props/model';

import { useMetaData } from './useMetaData';
import { useSite } from './useSite';
import { useTemplate } from './useTemplate';

// https://github.com/vercel/next.js/discussions/20784#discussioncomment-4101864
type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[];
};
declare const window: WindowWithDataLayer;

export const gtmDataLayerPush = (data: Record<string, unknown>) => {
  if (window && window.dataLayer) {
    window.dataLayer.push(data);
  }
};

export const useGTMSearchInput = () => {
  const router = useRouter();

  return {
    trackInput: (event: FormEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;
      if (value.length >= 3) {
        gtmDataLayerPush({
          event: 'SearchInput',
          searchTerm: value,
          pagePath: `/${router.locale}${router.asPath}`,
          elementId: event.currentTarget.id,
        });
      }
    },
  };
};

export const useGTMPageView = () => {
  const router = useRouter();
  const { metaData } = useMetaData();
  const {
    isPeopleDetail,
    isNewsPublicationEventDetail,
    isEventDetail,
    isPublicationDetail,
    isNewsDetail,
    isClientStory,
    isServiceDetail,
  } = useTemplate();
  const { isTCSite } = useSite();
  const { sitecoreContext } = useSitecoreContext();
  const triggeredOnPageLoad = useRef(false);

  useEffect(() => {
    const isInitialPageLoad = !triggeredOnPageLoad.current;

    const trackPageView = () => {
      try {
        if (router.pathname !== '/404') {
          const entityData = isPeopleDetail
            ? getPeopleDetailData()
            : isNewsPublicationEventDetail
              ? getNewsPublicationEventData()
              : isClientStory
                ? getClientStoriesData()
                : isServiceDetail
                  ? getServiceDetailData()
                  : {};

          // Trigger a custom event called SPAPageView with the page data.
          // GTM will be configured to listen to this event.
          const data = {
            event: 'SPAPageView',
            pagePath: `/${router.locale}${router.asPath}`,
            pageTitle: metaData.title,
            template: sitecoreContext.route?.templateName,
            ...entityData,
          };

          gtmDataLayerPush(data);

          if (!isInitialPageLoad) {
            // Trigger the gtm.load event on subsequent navigations which resets most
            // GTM triggers (like scroll depth)
            gtmDataLayerPush({ event: 'gtm.load' });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (isInitialPageLoad && window && window.dataLayer) {
      trackPageView();
      triggeredOnPageLoad.current = true;
    }

    const handler = () => setTimeout(trackPageView, 100);
    router.events.on('routeChangeComplete', handler);
    return () => {
      router.events.off('routeChangeComplete', handler);
    };
  }, [router, sitecoreContext]);

  const getPeopleDetailData = () => {
    const fields = sitecoreContext.route
      ?.fields as Website.Project.Main.PageTypes.People.Professional['fields'];

    return {
      spokenLanguages: convertListToString(fields?.spokenLanguages),
      keyPractices: convertListToString(isTCSite ? fields?.keyPracticesTC : fields?.keyPractices),
      ...getServicesIndustries(isTCSite ? fields?.servicesTC : fields?.services),
    };
  };

  const getNewsPublicationEventData = () => {
    type AllFields = Website.Project.Main.PageTypes.Insights.Publication['fields'] &
      Website.Project.Main.PageTypes.Insights.News['fields'] &
      Website.Project.Main.PageTypes.Insights.Event['fields'];

    const fields = sitecoreContext.route?.fields as AllFields;
    const lang = sitecoreContext.language;
    const data: Record<string, string> = {
      date: parseDate(fields?.date || fields?.startDateandTime),
      ...getServicesIndustries(isTCSite ? fields?.servicesTC : fields?.services),
    };

    const { authors = [], professionals = [], speakers = [] } = fields || {};
    const people = convertPeopleListToString(
      [...authors, ...professionals, ...speakers].flat(),
      fields?.additionalAuthors || fields?.additionalSpeakers,
      lang
    );

    if (isPublicationDetail) {
      data.authors = people;
    } else if (isEventDetail) {
      data.speakers = people;
    } else if (isNewsDetail) {
      data.relatedPeople = people;
    }

    return data;
  };

  const getClientStoriesData = () => {
    const fields = sitecoreContext.route
      ?.fields as Website.Project.Main.PageTypes.Insights.ClientStory['fields'];

    return {
      date: parseDate(fields?.date),
      displayDate: fields?.displayDate?.value || '',
      ...getServicesIndustries(isTCSite ? fields?.servicesTC : fields?.services),
    };
  };

  const getServiceDetailData = () => {
    const fields = sitecoreContext.route
      ?.fields as Website.Project.Main.PageTypes.Services.Service['fields'];
    const lang = sitecoreContext.language;

    return {
      professionals: convertPeopleListToString(fields?.professionals, undefined, lang),
      ...getServicesIndustries(isTCSite ? fields?.servicesTC : fields?.services),
    };
  };
};

const convertListToString = (listing: { fields?: { name?: { value?: string } } }[] = []) =>
  listing
    .map((item) => item?.fields?.name?.value)
    .filter(Boolean)
    .join(', ');

const convertPeopleListToString = (
  listing: Website.Feature.People._Person[] = [],
  additional?: Field<string>,
  lang = 'en'
) =>
  listing
    .map((person) => getName(person.fields, lang).value)
    .concat(additional?.value || '')
    .filter(Boolean)
    .join(', ');

const parseDate = (date: Field<string> | undefined) => {
  const value = date?.value || '';
  const noDateStartingString = '0001';
  const dateString = value.startsWith(noDateStartingString) ? '' : value;
  const dateOnly = dateString.split('T')[0] || '';
  return dateOnly;
};

const getServicesIndustries = (listing: Website.Feature.Services._Service[] = []) => {
  const industries = listing.filter((item) => item.url?.toLowerCase().includes('/industries/'));
  const services = listing.filter((item) => item.url?.toLowerCase().includes('/services/'));
  return {
    industries: convertListToString(industries),
    services: convertListToString(services),
  };
};
