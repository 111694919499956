import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import StartIndex from 'components/BaseHelpers/StartIndex';
import StopIndex from 'components/BaseHelpers/StopIndex';

import Text from '../Text';

import styles from './ModuleHeader.module.scss';

export type ModuleHeaderProps = {
  header?: Field<string>;
  defaultHeader?: Field<string>;
  tag?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  variant?: 'double-line' | 'single-line' | '';
  className?: string;
};

const ModuleHeader = ({
  header,
  defaultHeader,
  tag = 'h2',
  variant = '',
  className = '',
}: ModuleHeaderProps): JSX.Element => {
  const { isEE } = useExpEditor();

  if (isEE && defaultHeader) {
    <div>
      If no value then defaults to default header value: {<Text field={defaultHeader}></Text>}
    </div>;
  }

  if (isEE || header) {
    return (
      <>
        <StopIndex />
        <Text
          tag={tag}
          className={cn(styles[`main`], styles[variant], className, 'module-header')}
          field={header}
        ></Text>
        <StartIndex />
      </>
    );
  } else if (defaultHeader) {
    return (
      <>
        <StopIndex />
        <Text
          tag={tag}
          className={cn(styles[`main`], styles[variant], className)}
          field={defaultHeader}
        ></Text>
        <StartIndex />
      </>
    );
  }
  return <></>;
};

export default ModuleHeader;
